import "./FooterTable.css";
import React from "react";

function FooterTable() {
  return (
    <div className="footer_table">
      <div className="footer_table_content footer_table_contact_info">
        {"شركة جزلة للاستشارات الصحية │ Jazlah Healthcare Consulting\n"}
        <a href="https://www.dermax.app/">www.dermax.app</a>
        {
          "│ C.R: 1010670609 \n رقم ترخيص وزارة الصحة السعودية : 1400036519 \n مصدر الترخيص : المديرية العامة للشؤون الصحية بالرياض"
        }
      </div>
    </div>
  );
}

export default FooterTable;
