import './Drugs.css'
import React from 'react'

export default function Drugs({ drug }) {
    return (
      <div className="drug-container avenir-font-ar">
        <div className="general drug-name">
          <strong>Drug Name</strong>
          <span>{drug.drug}</span>
        </div>
        <div className="drug-info">
          <div className="general">
            <strong>Dose/Unit</strong>
            <span>{`${drug.dose} ${drug.unit}`}</span>
          </div>
          <div className="general">
            <strong>Frequency</strong>
            <span>{drug.frequency}</span>
          </div>
          <div className="general">
            <strong>Route</strong>
            <span>{drug.route}</span>
          </div>
          <div className="general">
            <strong>Duration</strong>
            <span>{`${drug.duration} ${drug.duration_type}`}</span>
          </div>
        </div>
        <div className="general other-instraction">
          <strong>Indications and other instruction</strong>
          <span>
            {drug.other_instructions ? drug.other_instructions : "NONE"}
          </span>
        </div>
      </div>
    );
}