import "./Success.css";
import React from "react";
import logo from "../../images/logoNoBack.png";
import Drugs from "./RelatedComponents/Drugs/Drugs";
import PrescriptionInfo from "./RelatedComponents/PrescriptionInfo/PrescriptionInfo";
import Diagnosis from "./RelatedComponents/Diagnosis/Diagnosis";
import FooterTable from "../FooterTable/FooterTable";

export default function Success({ data }) {
  return (
    <div className="success avenir-font-ar">
      <div className="success__content">
        <div className="logo_container">
          <img className="logo" src={logo} alt="logo" />
        </div>
        <div className="msg">
          <strong>
            <span>يرجى التحقق من مطابقة الوصفة</span>
            <br />
            <span>Please verify prescription details</span>
          </strong>
        </div>
        <div className="table_header">Prescrition Information</div>
        <PrescriptionInfo data={data} />
        <div className="table_header">Diagnosis</div>
        <Diagnosis data={data} />
        <div className="table_header">Drugs</div>
        <div className="table_content">
          {data.medicines.map((drug) => (
            <Drugs drug={drug} key={drug.drug} />
          ))}
        </div>
        <div className="footer_table_content">
          تم إصدار الوصفة الطبية عن طريق النظام الآلي لمنصة ديرما إكس ولا تحتاج
          لختم رسمي.
          <FooterTable />
        </div>
      </div>
    </div>
  );
}
