import "./Verify.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Success from "../Success/Success";
import Failure from "../Failure/Failure";
import LoadingSpinner from "../Spinner/Spinner";


function Verify() {
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(true);
  const URL = window.location.href;
  const keyArray = URL.split("/");
  const key = keyArray[keyArray.length - 1];
  useEffect(() => {
    //Change to key
    axios
      .get(
        `https://api.dermax.app/patient/prescriptions/verify/${key}/`
      )
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setStatus(false);
        setLoading(false);
        console.log(err);
      });
  }, []);
  return (
    <div className="Verify">
      <header className="Verify-header">
        {loading ? (
          <LoadingSpinner />
        ) : status ? (
          <Success data={data} />
        ) : (
          <Failure />
        )}
      </header>
    </div>
  );
}

export default Verify;
