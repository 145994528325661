import "./Failure.css";
import React from "react";
import logo from "../../images/Logo-purple.svg";
import FooterTable from "../FooterTable/FooterTable";
import icon from "../../images/icon.png";

export default function Failure({ data }) {
  console.log(data);
  return (
    <div className="failure avenir-font-ar">
      <div className="failure__content">
        <div className="logo_container">
          <img className="logo" src={logo} alt="logo" />
        </div>
        <div className="failure_message_container">
          <img className="icon" src={icon} alt="logo" />
          <h2 className="failure_message_text">
            {"Sorry !\nThis Prescrition is not valid.\n"}
            {"! عذرًا\n.هذه الوصفة غير صالحة"}
          </h2>
        </div>
        <FooterTable />
      </div>
    </div>
  );
}
