import "./Home.css";
import React from "react";
import en_photo from "../../images/scan-code-en.png";
import ar_photo from "../../images/scan-code-ar.png";
import logo from "../../images/Logo-purple.svg";
import icon from "../../images/pers-icon.png";



export default function Home() {
  return (
    <div className="home-container avenir-font avenir-font-ar">
      <div className="logo_container">
        <img className="logo" src={logo} alt="logo" />
      </div>
      <div className="content_container">
        <img className="icon" src={icon} alt="logo" />

        <div className="above-msg">
          <p> نظام ديرما إكس للتحقق من الوصفات الطبية </p>
          <p>DermaX Prescription Verification System</p>
        </div>
        <div className="photos-container">
          <img className="en-photo" src={en_photo} alt="logo" />
          <img className="ar-photo" src={ar_photo} alt="logo" />
        </div>
      </div>
    </div>
  );
}
