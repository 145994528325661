import "./App.css";
import React from "react";
import Verify from "./Components/Verify/Verify";
import Home from "./Components/Home/Home";
import { Routes, Route } from "react-router-dom";

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/:key" element={<Verify />} />
        <Route
          path="/"
          element={
            <Home />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
