import "./PrescriptionInfo.css";
import React from "react";

function PrescriptionInfo({data}) {
  return (
    <div className="table_content avenir-font-ar">
      <div className="info_table_row">
        <div className="info_table_title">
          <strong>
            <span>رقم الاستشارة</span>
            <br />
            <span>Consultation ID</span>
          </strong>
        </div>
        <div className="info_table_content">{data.consultation_id}</div>
      </div>
      <div className="info_table_row">
        <div className="info_table_title">
          <strong>
            <span>رقم الوصفة الطبية</span>
            <br />
            <span>Prescription Ref.</span>
          </strong>
        </div>
        <div className="info_table_content">{data.prescription_id}</div>
      </div>

      <div className="info_table_row">
        <div className="info_table_title">
          <strong>
            <span>تاريخ الإصدار</span>
            <br />
            <span>Issued Date</span>
          </strong>
        </div>
        <div className="info_table_content">{data.date_created}</div>
      </div>
      <div className="info_table_row">
        <div className="info_table_title">
          <strong>
            <span>اسم العميل</span>
            <br />
            <span>Client name</span>
          </strong>
        </div>
        <div className="info_table_content">{data.patient_name}</div>
      </div>
      <div className="info_table_row">
        <div className="info_table_title">
          <strong>
            <span>رقم إثبات العميل</span>
            <br />
            <span>Client ID</span>
          </strong>
        </div>
        <div className="info_table_content">{data.patient_national_id}</div>
      </div>
      <div className="info_table_row">
        <div className="info_table_title">
          <strong>
            <span>تاريخ الميلاد</span>
            <br />
            <span>DOB</span>
          </strong>
        </div>
        <div className="info_table_content">{data.date_of_birth}</div>
      </div>
      <div className="info_table_row before-last">
        <div className="info_table_title">
          <strong>
            <span>الجنس</span>
            <br />
            <span>Gender</span>
          </strong>
        </div>
        <div className="info_table_content">{data.gender}</div>
      </div>
      {data.pregnancy && (
        <div className="info_table_row last">
          <div className="info_table_title">
            <strong>
              <span>حالة الحمل</span>
              <br />
              <span>Pregnancy</span>
            </strong>
          </div>
          <div className="info_table_content">
            <span>{data.pregnancy}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default PrescriptionInfo;
