import "./Diagnosis.css";
import React from "react";

function Diagnosis({data}) {
  return (
    <div>
      <div className="table_content avenir-font">
        <div>
          <div className="diagnosis_table_row">
            <div className="diagnosis_table_title">Doctor Name</div>
            <div className="diagnosis_table_content">{data.doctor_name}</div>
          </div>
          <div className="diagnosis_table_row">
            <div className="diagnosis_table_title">SCFHS ID</div>
            <div className="diagnosis_table_content">
              {data.doctor_mumaris_id}
            </div>
          </div>
          <div className="diagnosis_table_row">
            <div className="diagnosis_table_title">Diagnosis Summary</div>
            <div className="diagnosis_table_content">
              {data.diagnosis ? data.diagnosis : "_"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Diagnosis;
